export { default as AlertColored } from "./AlertColored";
export { default as Amex } from "./Amex";
export { default as Aura } from "./Aura";
export { default as BuildingColored } from "./BuildingColored";
export { default as BuyColored } from "./BuyColored";
export { default as CalendarClockColored } from "./CalendarClockColored";
export { default as CalendarColored } from "./CalendarColored";
export { default as ChatColored } from "./ChatColored";
export { default as CheckCircleColored } from "./CheckCircleColored";
export { default as CheckColored } from "./CheckColored";
export { default as CircleColored } from "./CircleColored";
export { default as DinersClub } from "./DinersClub";
export { default as Discover } from "./Discover";
export { default as Elo } from "./Elo";
export { default as GlobalColored } from "./GlobalColored";
export { default as HeartColored } from "./HeartColored";
export { default as Hipercard } from "./Hipercard";
export { default as Jcb } from "./Jcb";
export { default as LocationColored } from "./LocationColored";
export { default as Mastercard } from "./Mastercard";
export { default as MoneyColored } from "./MoneyColored";
export { default as Paypal } from "./Paypal";
export { default as Pix } from "./Pix";
export { default as QuestionColored } from "./QuestionColored";
export { default as ShieldColored } from "./ShieldColored";
export { default as SuccessColored } from "./SuccessColored";
export { default as UserColored } from "./UserColored";
export { default as Visa } from "./Visa";
export { default as WhatsappColored } from "./WhatsappColored";

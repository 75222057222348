import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgApple = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="#111827"
        d="M18.29 12.726c.033 3.625 3.18 4.832 3.215 4.847-.027.085-.503 1.72-1.658 3.407-.998 1.46-2.035 2.913-3.667 2.943-1.604.03-2.12-.951-3.953-.951-1.834 0-2.407.921-3.925.98-1.576.06-2.775-1.577-3.782-3.031C2.463 17.947.89 12.517 3.002 8.85 4.05 7.032 5.924 5.88 7.958 5.85c1.548-.03 3.008 1.04 3.954 1.04.945 0 2.72-1.287 4.585-1.098.781.033 2.974.316 4.381 2.376-.113.07-2.615 1.527-2.588 4.558m-3.015-8.901c.837-1.013 1.4-2.422 1.246-3.825-1.206.048-2.664.803-3.528 1.815-.776.897-1.454 2.331-1.271 3.706 1.344.104 2.717-.683 3.553-1.696"
      />
    </svg>
  );
};
export default SvgApple;

export { default as AddFill } from "./AddFill";
export { default as Add } from "./Add";
export { default as Adjustments } from "./Adjustments";
export { default as Alarm } from "./Alarm";
export { default as App } from "./App";
export { default as Apple } from "./Apple";
export { default as Archive } from "./Archive";
export { default as ArrowCircleDown } from "./ArrowCircleDown";
export { default as ArrowCircleLeft } from "./ArrowCircleLeft";
export { default as ArrowCircleRight } from "./ArrowCircleRight";
export { default as ArrowCircleUp } from "./ArrowCircleUp";
export { default as ArrowDown } from "./ArrowDown";
export { default as ArrowLeft } from "./ArrowLeft";
export { default as ArrowRight } from "./ArrowRight";
export { default as ArrowUp } from "./ArrowUp";
export { default as AtSymbol } from "./AtSymbol";
export { default as Backspace } from "./Backspace";
export { default as Ban } from "./Ban";
export { default as Bank } from "./Bank";
export { default as Bell } from "./Bell";
export { default as BluetoothOff } from "./BluetoothOff";
export { default as Bluetooth } from "./Bluetooth";
export { default as Bolt } from "./Bolt";
export { default as BookOpen } from "./BookOpen";
export { default as Book } from "./Book";
export { default as Bookmark } from "./Bookmark";
export { default as Briefcase } from "./Briefcase";
export { default as Building1 } from "./Building1";
export { default as Building } from "./Building";
export { default as Calculator } from "./Calculator";
export { default as CalendarDay } from "./CalendarDay";
export { default as CalendarFill } from "./CalendarFill";
export { default as CalendarTime } from "./CalendarTime";
export { default as Calendar } from "./Calendar";
export { default as Camera } from "./Camera";
export { default as Cart } from "./Cart";
export { default as CashDuplicate } from "./CashDuplicate";
export { default as Cash } from "./Cash";
export { default as Cast } from "./Cast";
export { default as Certificate } from "./Certificate";
export { default as ChartPie } from "./ChartPie";
export { default as Chart } from "./Chart";
export { default as Chat } from "./Chat";
export { default as CheckCircle } from "./CheckCircle";
export { default as Check } from "./Check";
export { default as Checkbox } from "./Checkbox";
export { default as CircleCheck } from "./CircleCheck";
export { default as Clean } from "./Clean";
export { default as Click } from "./Click";
export { default as Clock } from "./Clock";
export { default as Code } from "./Code";
export { default as Coffee } from "./Coffee";
export { default as Color } from "./Color";
export { default as Connect } from "./Connect";
export { default as Copy } from "./Copy";
export { default as CornerDown } from "./CornerDown";
export { default as CornerLeft } from "./CornerLeft";
export { default as CornerRight } from "./CornerRight";
export { default as CornerUp } from "./CornerUp";
export { default as CreditCard } from "./CreditCard";
export { default as Cube } from "./Cube";
export { default as Dashboard } from "./Dashboard";
export { default as DatabaseExport } from "./DatabaseExport";
export { default as DatabaseImport } from "./DatabaseImport";
export { default as Database } from "./Database";
export { default as Desktop } from "./Desktop";
export { default as Diamond } from "./Diamond";
export { default as Disconnected } from "./Disconnected";
export { default as Discount } from "./Discount";
export { default as DislikeFill } from "./DislikeFill";
export { default as Dislike } from "./Dislike";
export { default as DoctorWithClipboard } from "./DoctorWithClipboard";
export { default as DoctorWithStethoAndClipboard } from "./DoctorWithStethoAndClipboard";
export { default as DoctorWithStethoscope } from "./DoctorWithStethoscope";
export { default as Doctor } from "./Doctor";
export { default as DocumentDownload } from "./DocumentDownload";
export { default as DocumentDuplicate } from "./DocumentDuplicate";
export { default as DocumentPlus } from "./DocumentPlus";
export { default as DocumentShare } from "./DocumentShare";
export { default as Document } from "./Document";
export { default as Dolar } from "./Dolar";
export { default as DoorEnter } from "./DoorEnter";
export { default as DoorExit } from "./DoorExit";
export { default as Door } from "./Door";
export { default as Dot } from "./Dot";
export { default as DotsHorizontal } from "./DotsHorizontal";
export { default as DotsVertical } from "./DotsVertical";
export { default as Down } from "./Down";
export { default as DownloadCloud } from "./DownloadCloud";
export { default as Download } from "./Download";
export { default as Duplicate1 } from "./Duplicate1";
export { default as Edit1 } from "./Edit1";
export { default as Edit } from "./Edit";
export { default as ExclamationCircle } from "./ExclamationCircle";
export { default as Exclamation } from "./Exclamation";
export { default as Expand } from "./Expand";
export { default as EyeOff } from "./EyeOff";
export { default as Eye } from "./Eye";
export { default as Facebook } from "./Facebook";
export { default as FemaleDoctorWithClipboard } from "./FemaleDoctorWithClipboard";
export { default as FemaleDoctorWithSmartphone } from "./FemaleDoctorWithSmartphone";
export { default as FileExport } from "./FileExport";
export { default as Filter } from "./Filter";
export { default as FingerPrint } from "./FingerPrint";
export { default as Fire } from "./Fire";
export { default as Flag } from "./Flag";
export { default as Folder } from "./Folder";
export { default as Frame } from "./Frame";
export { default as Gift } from "./Gift";
export { default as Github } from "./Github";
export { default as Google } from "./Google";
export { default as GraphList } from "./GraphList";
export { default as Graphic } from "./Graphic";
export { default as Grid } from "./Grid";
export { default as Hand } from "./Hand";
export { default as Hashtag } from "./Hashtag";
export { default as HelpCircle } from "./HelpCircle";
export { default as History } from "./History";
export { default as Home } from "./Home";
export { default as ImageOff } from "./ImageOff";
export { default as Image } from "./Image";
export { default as Inbox } from "./Inbox";
export { default as InformationCircle } from "./InformationCircle";
export { default as Instagram } from "./Instagram";
export { default as Invite } from "./Invite";
export { default as Key } from "./Key";
export { default as Laptop } from "./Laptop";
export { default as Left } from "./Left";
export { default as Light } from "./Light";
export { default as LikeFill } from "./LikeFill";
export { default as Like } from "./Like";
export { default as Link1 } from "./Link1";
export { default as Link } from "./Link";
export { default as Linkedin } from "./Linkedin";
export { default as ListSearch } from "./ListSearch";
export { default as Load } from "./Load";
export { default as LocationMarker } from "./LocationMarker";
export { default as LockClosed } from "./LockClosed";
export { default as LockOpen } from "./LockOpen";
export { default as Login } from "./Login";
export { default as Logout } from "./Logout";
export { default as LoveFill } from "./LoveFill";
export { default as Love } from "./Love";
export { default as MailForward } from "./MailForward";
export { default as MailOpen } from "./MailOpen";
export { default as Mail } from "./Mail";
export { default as Map } from "./Map";
export { default as Maps } from "./Maps";
export { default as Mask } from "./Mask";
export { default as Medical } from "./Medical";
export { default as Megaphone } from "./Megaphone";
export { default as Menu1 } from "./Menu1";
export { default as Menu2 } from "./Menu2";
export { default as Menu3 } from "./Menu3";
export { default as Menu } from "./Menu";
export { default as Messages } from "./Messages";
export { default as MicrophoneOff } from "./MicrophoneOff";
export { default as Microphone } from "./Microphone";
export { default as MinusCircle } from "./MinusCircle";
export { default as Minus } from "./Minus";
export { default as Mobile } from "./Mobile";
export { default as Moon } from "./Moon";
export { default as News } from "./News";
export { default as Notes } from "./Notes";
export { default as OneLivLogo } from "./OneLivLogo";
export { default as Paint } from "./Paint";
export { default as PaperClip } from "./PaperClip";
export { default as PasteLink } from "./PasteLink";
export { default as PasteList } from "./PasteList";
export { default as PatientsFill } from "./PatientsFill";
export { default as Patients } from "./Patients";
export { default as PhoneIn } from "./PhoneIn";
export { default as PhoneOff } from "./PhoneOff";
export { default as PhoneOut } from "./PhoneOut";
export { default as Phone } from "./Phone";
export { default as Pill } from "./Pill";
export { default as PinFill } from "./PinFill";
export { default as Pin } from "./Pin";
export { default as PlusCircle } from "./PlusCircle";
export { default as Plus } from "./Plus";
export { default as Printer } from "./Printer";
export { default as Qrcode } from "./Qrcode";
export { default as Quote } from "./Quote";
export { default as Real } from "./Real";
export { default as ReceiptDisc } from "./ReceiptDisc";
export { default as ReceiptMoney } from "./ReceiptMoney";
export { default as ReceiptRefund } from "./ReceiptRefund";
export { default as Receipt } from "./Receipt";
export { default as Referral } from "./Referral";
export { default as Refresh } from "./Refresh";
export { default as Register } from "./Register";
export { default as Right } from "./Right";
export { default as Rocket } from "./Rocket";
export { default as Rotate } from "./Rotate";
export { default as Rss } from "./Rss";
export { default as Sad } from "./Sad";
export { default as School } from "./School";
export { default as Scissors } from "./Scissors";
export { default as Search } from "./Search";
export { default as Selector } from "./Selector";
export { default as Send } from "./Send";
export { default as Server } from "./Server";
export { default as Service } from "./Service";
export { default as SettingsFill } from "./SettingsFill";
export { default as Settings } from "./Settings";
export { default as Share } from "./Share";
export { default as ShieldCheck } from "./ShieldCheck";
export { default as ShieldX } from "./ShieldX";
export { default as Smile } from "./Smile";
export { default as SofaFill } from "./SofaFill";
export { default as Sofa } from "./Sofa";
export { default as StarFill } from "./StarFill";
export { default as Star } from "./Star";
export { default as Store } from "./Store";
export { default as Sun } from "./Sun";
export { default as Support } from "./Support";
export { default as SwitchHorizontal } from "./SwitchHorizontal";
export { default as SwitchVertical } from "./SwitchVertical";
export { default as Tabled } from "./Tabled";
export { default as Tag } from "./Tag";
export { default as Template } from "./Template";
export { default as Terminal } from "./Terminal";
export { default as Ticket } from "./Ticket";
export { default as Trade } from "./Trade";
export { default as Trash } from "./Trash";
export { default as TrendingDown } from "./TrendingDown";
export { default as TrendingUp } from "./TrendingUp";
export { default as Twitter } from "./Twitter";
export { default as UpDown } from "./UpDown";
export { default as Up } from "./Up";
export { default as UploadCloud } from "./UploadCloud";
export { default as Upload } from "./Upload";
export { default as User1 } from "./User1";
export { default as UserFill } from "./UserFill";
export { default as UserPlus } from "./UserPlus";
export { default as User } from "./User";
export { default as Users } from "./Users";
export { default as Vaccine } from "./Vaccine";
export { default as Video } from "./Video";
export { default as VolumeOff } from "./VolumeOff";
export { default as Volume } from "./Volume";
export { default as Whatsapp } from "./Whatsapp";
export { default as Wifi } from "./Wifi";
export { default as World } from "./World";
export { default as XCircle } from "./XCircle";
export { default as X } from "./X";
export { default as Youtube } from "./Youtube";
export { default as Zip } from "./Zip";
